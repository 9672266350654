.social-icon, .arrow-icon {
    width: 35px;
    height: 35px;
}

#kacper-img, #mask-img {
    position: absolute;
    width: 100%;
    background-repeat: no-repeat;
    background-size:  auto 60%;
    background-position: -50px bottom;
    background-attachment: fixed;
}

#kacper-img {
    height: 100%;
    z-index: 0;
}

#mask-img {
    height: 100vh;
    z-index: 10;
    bottom: 0;
}

.bg-texture {
    background-repeat: repeat;
    background-image: url('./assets/textures/y-so-serious-nobg-op50.png')
}

@screen sm {
    #kacper-img, #mask-img {
        background-size: auto 80%;
        background-position: left bottom;
    }
}

@screen 2xl {
    #kacper-img, #mask-img {
        background-position:  calc((100vw - 1536px)/2) bottom;
    }
}

.break-word {
    word-break: break-word;
}

@supports (-webkit-touch-callout: none) {
    #kacper-img, #mask-img {
        height: 100vh;
        background-attachment: scroll;
    }
  }
  
  @supports not (-webkit-touch-callout: none) {
    .not-ios-hide {
        display: none;
    }
    /* CSS for other than iOS devices */ 
  }